<template>
  <div>
    <vx-card>
      <div class="flex justify-between items-center gap-3">
        <div class="w-full">
          <label class="text-sm text-san-juan font-bold">Buscar</label>
          <vs-input
            class="mb-4 w-full md:mb-0 mr-4"
            v-model="searchQuery"
            placeholder="Buscar"
          />
        </div>
        <div class="flex gap-3">
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold"
              >Fecha de inicio</label
            >
            <datepicker
              :language="langEs"
              name="start-date"
              v-model="startDate"
            ></datepicker>
          </div>
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
            <datepicker
              :language="langEs"
              name="end-date"
              v-model="endDate"
            ></datepicker>
          </div>

          <vs-select
            label="Estado"
            class="w-full"
            v-model="statusSelected"
            placeholder="Seleccione un estado"
            autocomplete
          >
            <vs-select-item :value="'ALL'" :text="'Todos'" />
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in statuses"
            />
          </vs-select>
          <vs-select
            label="Contratistas"
            class="w-full"
            v-model="deliverymanSelected"
            placeholder="Seleccione un contratista"
            autocomplete
          >
            <vs-select-item :value="'ALL'" :text="'Todos'" />
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="`${item.code ? item.code : 'N/A'} - ${
                item.user.first_name + ' ' + item.user.last_name
              }`"
              v-for="(item, index) in deliverymen"
            />
          </vs-select>

          <div class="mt-6">
            <vs-button
              type="border"
              class="mb-4 md:mb-0 mr-3 whitespace-no-wrap"
              @click="exportWithdrawRequest"
            >
              Exportar
            </vs-button>
          </div>
        </div>
      </div>

      <vs-prompt
        :active.sync="toggleEditWithdrawImagePrompt"
        class="modal"
        title="Cargar foto"
        @accept="importImage"
        @cancel="resetImagePrompt"
        accept-text="Cargar"
        cancel-text="Descartar"
      >
        <div>
          <h1 class="font-semibold text-lg mb-4">
            Sube un la imagen que desea
          </h1>
          <UploadImageModal
            :currentImage="currentImage"
            :onSuccess="onUploadImage"
          />
        </div>
      </vs-prompt>

      <div v-if="!loading && !withdrawRequestHistoryLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="withdrawRequestHistory"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import { useAgGrid } from '@/composable/useAgGrid.js';
import { DELIVERYMEN, WITHDRAW_REQUESTS_HISTORY } from '@/graphql/queries.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';

import CellRendererBank from './cell-renderer/CellRendererBank.vue';
import CellRendererWithdrawRequestHistoryStatus from './cell-renderer/CellRendererWithdrawRequestHistoryStatus.vue';
import CellRendererImage from './cell-renderer/CellRendererImage.vue';
import CellRendererEditImage from './cell-renderer/CellRendererEditImage.vue';
import CellRendererCopyField from './cell-renderer/CellRendererCopyField.vue';
import UploadImageModal from '@/components/socio/modals/UploadImageModal.vue';

import { UPDATE_WITHDRAW_REQUEST_IMAGE } from '@/graphql/mutations';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererPercentAmount,
    CellRendererBank,
    CellRendererWithdrawRequestHistoryStatus,
    CellRendererImage,
    CellRendererEditImage,
    CellRendererCopyField,
    UploadImageModal,
    Datepicker,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 100,
          filter: true,
        },
        {
          headerName: 'Estado',
          field: 'status',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererWithdrawRequestHistoryStatus',
        },
        {
          headerName: 'Código Aliado',
          field: 'deliveryman.code',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Nombre Aliado',
          field: 'deliveryman.user.first_name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Apellido Aliado',
          field: 'deliveryman.user.last_name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Cantidad',
          field: 'amount',
          filter: true,
          width: 130,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Cuenta bancaria',
          field: 'bankAccount.name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Banco',
          field: 'bankAccount.bank',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererBank',
        },
        {
          headerName: 'Número de cuenta',
          field: 'bankAccount.number',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererCopyField',
        },
        {
          headerName: 'Número de cédula',
          field: 'bankAccount.document',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererCopyField',
        },
        {
          headerName: 'Caja',
          field: 'account.name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Evidencia',
          field: 'image',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Editar',
          field: 'image',
          width: 120,
          filter: true,
          cellRendererFramework: 'CellRendererEditImage',
        },
        {
          headerName: 'Fecha de solicitud',
          field: 'created_at',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Fecha de aprobación',
          field: 'approved_at',
          width: 200,
          filter: true,
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
      startDate: new Date(),
      endDate: new Date(),
      statuses: [
        {
          text: 'Pendiente',
          value: 'PENDING',
        },
        {
          text: 'Aprobado',
          value: 'APPROVED',
        },
        {
          text: 'Denegado',
          value: 'DENIED',
        },
      ],
      deliverymanSelected: null,
      statusSelected: null,
      toggleEditWithdrawImagePrompt: false,
      currentWithdrawRequest: null,
      currentImage: null,
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');

    const deliverymanSelected = computed(() =>
      state.deliverymanSelected !== 'ALL' ? state.deliverymanSelected : null
    );
    const statusSelected = computed(() =>
      state.statusSelected !== 'ALL' ? state.statusSelected : null
    );
    const sDate = computed(() => moment(state.startDate).format('YYYY-MM-DD'));
    const eDate = computed(() => moment(state.endDate).format('YYYY-MM-DD'));

    const {
      result: withdrawRequestHistoryResult,
      loading: withdrawRequestHistoryLoading,
      refetch: withdrawRequestHistoryRefetch,
    } = useQuery(WITHDRAW_REQUESTS_HISTORY, {
      page: currentPage,
      first: first,
      search: searchQuery,
      startDate: sDate,
      endDate: eDate,
      deliveryman_id: deliverymanSelected,
      status: statusSelected,
    });

    watch(withdrawRequestHistoryLoading, (value) => {
      state.loading = value;
    });

    const withdrawRequestHistory = useResult(
      withdrawRequestHistoryResult,
      [],
      (data) => data.withdrawRequestHistory.data
    );

    const paginatorInfo = useResult(
      withdrawRequestHistoryResult,
      [],
      (data) => ({
        total: data.withdrawRequestHistory.paginatorInfo.total,
        perPage: data.withdrawRequestHistory.paginatorInfo.perPage,
      })
    );

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { result: deliverymenResult, loading: deliverymanLoading } =
      useQuery(DELIVERYMEN);
    const deliverymen = useResult(
      deliverymenResult,
      [],
      (data) => data.deliverymen
    );
    watch(deliverymanLoading, (value) => {
      state.loading = value;
    });

    const { state: agGridState } = useAgGrid({ root });

    const editImage = ({ withdrawRequest }) => {
      state.toggleEditWithdrawImagePrompt = true;
      state.currentImage = null;
      state.currentWithdrawRequest = withdrawRequest;
    };

    const onUploadImage = (image) => {
      state.currentImage = image;
    };
    const importImage = async () => {
      if (!state.currentImage) {
        root.$vs.notify({
          color: 'warning',
          title: 'Imagen obligatoria',
          text: 'Debe haber cargado una imagen.',
        });
        return;
      }
      try {
        state.loading = true;
        await root.$apollo.mutate({
          mutation: UPDATE_WITHDRAW_REQUEST_IMAGE,
          variables: {
            input: {
              id: state.currentWithdrawRequest.id,
              image: state.currentImage,
            },
          },
        });

        root.$vs.notify({
          color: 'success',
          title: 'Correctamente Actualizado',
          text: 'Se actualizó la aprobación de las solicitudes.',
        });
        withdrawRequestHistoryRefetch();
        state.loading = false;
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    };
    const resetImagePrompt = () => {
      state.currentImage = null;
    };

    const copyValue = ({ value }) => {
      navigator.clipboard.writeText(value);
      root.$vs.notify({
        color: 'success',
        title: '¡Copiado!',
        text: 'Se ha copiado en el portapapeles correctamente.',
      });
    };

    const exportWithdrawRequest = () => {
      const fileName = 'solicitudes.xlsx';
      root.$http
        .post(
          'api/withdraw-requests/export',
          {
            search: searchQuery.value,
            startDate: sDate.value,
            endDate: eDate.value,
            deliveryman_id: deliverymanSelected.value,
            status: statusSelected.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    return {
      ...toRefs(state),
      withdrawRequestHistoryLoading,
      paginationPageSize,
      agGridState,
      totalPages,
      currentPage,
      withdrawRequestHistory,
      searchQuery,
      deliverymen,
      langEs: es,
      editImage,
      importImage,
      onUploadImage,
      resetImagePrompt,
      copyValue,
      exportWithdrawRequest,
    };
  },
  created() {
    this.agGridState.gridOptions.context = {
      componentParent: this,
    };
  },
});
</script>
