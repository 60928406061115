<template>
  <div class="w-full h-full flex gap-2">
    <feather-icon
      icon="CopyIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="copy"
    />
    <span>{{ params.value }}</span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererCopyField',
  methods: {
    copy() {
      this.params.context.componentParent.copyValue({
        value: this.params.value,
      });
    },
  },
};
</script>
